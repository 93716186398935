<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      cheques: [],
      Cheques: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchCheques: null,
      ChequesSearchMode: false,
      data: {},
      // appprices:[]
    };
  },
  methods: {
    addCheques() {
      this.http.post("cheques", this.data).then(() => {
        this.get(this.page);
      });
    },
    editCheques() {
      console.log(this.data);
      this.http.put("cheques", this.data.id, this.data).then(() => {
        this.get(this.page);
      });
    },
    oneCheque(cheque) {
      // this.data = cheque
      // console.log(cheque);
      this.data = cheque;
      // this.data = Object.assign([], cheque)
    },
    search() {
      this.ChequesSearchMode = true;
      this.http
        .post("cheques/search", {
          search: this.searchCheques,
          col: "name",
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          this.cheques = res.data;
        });
    },
    cancelSearchMode() {
      this.searchCheques = "";
      this.ChequesSearchMode = false;
      this.get(this.page);
    },
    get(page) {
      console.log(page);
      this.http
        .post("cheques/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.cheques = res.data;
          this.data = {};
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('cheques.cheques')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box" style="width: 250px">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
              :placeholder="$t('users.searchplaceholder')"
              v-model="searchCheques"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
            <button
              @click="cancelSearchMode()"
              v-if="ChequesSearchMode"
              class="btn-close text-light"
              style="position: absolute; top: 12px; left: 250px"
            ></button>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#addModal"
          class="btn btn-light float-end mb-4"
        >
          <span
            class="bx bxs-plus-circle float-end fa-2x"
            style="color: #2a3042 !important"
          ></span>
        </button>
      </div>
    </div>
    <table class="table">
      <thead>
        <tr
          class="text-light text-center"
          style="background-color: #2a3042 !important"
        >
          <th scope="col">{{ $t("cheques.id") }}</th>
          <th scope="col">{{ $t("cheques.name") }}</th>
          <th scope="col">{{ $t("cheques.serial") }}</th>
          <th scope="col">{{ $t("cheques.status") }}</th>
          <th scope="col">{{ $t("cheques.cheque-date") }}</th>
          <th scope="col">{{ $t("cheques.operations") }}</th>
        </tr>
      </thead>
      <tr class="text-center" v-for="(cheque, index) in cheques" :key="cheque">
        <td scope="col">{{ index + 1 }}</td>
        <td scope="col">{{ cheque.name }}</td>
        <td scope="col">{{ cheque.serial }}</td>
        <td scope="col">{{ cheque.status }}</td>
        <td scope="col">{{ cheque.cheque_date }}</td>
        <td>
          <button
            class="btn bg-primary text-light"
            @click="oneCheque(cheque)"
            data-bs-toggle="modal"
            data-bs-target="#editModal"
          >
            {{ $t("popups.edit") }}
          </button>
        </td>
      </tr>
      <tbody></tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <!--   Apps  Pagination     -->
    <ul
      v-if="tot_pages > 20"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>

  <!--Start addModal-->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addCheques()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("cheques.add-cheque") }}
            </h5>
            <button
              style="margin: 0"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <label for="name">{{ $t("cheques.name") }}</label>
            <input
              required
              id="name"
              type="text"
              v-model="data.name"
              class="form-control"
            />
            <label class="form-label" for="serial">{{
              $t("cheques.serial")
            }}</label>
            <input
              required
              id="serial"
              type="text"
              class="form-control"
              v-model="data.serial"
            />
            <label required class="form-label" for="status">{{
              $t("cheques.status")
            }}</label>
            <select
              required
              class="form-select"
              aria-label="Default select example"
              v-model="data.status"
            >
              <option selected disabled>{{ $t("cheques.statues") }}</option>
              <option value="paid">{{ $t("cheques.paid") }}</option>
              <option value="unpaid">{{ $t("cheques.unpaid") }}</option>
              <option value="rejected">{{ $t("cheques.rejected") }}</option>
            </select>
            <label for="date">Cheque Date</label>
            <input
              required
              type="date"
              class="form-control"
              v-model="data.cheque_date"
            />
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.add") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End addModal-->

  <!--Start Edit Model-->
  <div
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editCheques()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("cheques.edit-cheque") }}
            </h5>
            <button
              style="margin: 0 !important"
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <label for="name">{{ $t("cheques.name") }}</label>
            <input
              required
              id="name"
              type="text"
              v-model="data.name"
              class="form-control"
            />
            <label class="form-label" for="serial">{{
              $t("cheques.serial")
            }}</label>
            <input
              required
              id="serial"
              type="text"
              class="form-control"
              v-model="data.serial"
            />
            <label required class="form-label" for="status">{{
              $t("cheques.status")
            }}</label>
            <select
              required
              class="form-select"
              aria-label="Default select example"
              v-model="data.status"
            >
              <option selected disabled>Status</option>
              <option value="paid">{{ $t("cheques.paid") }}</option>
              <option value="unpaid">{{ $t("cheques.unpaid") }}</option>
              <option value="rejected">{{ $t("cheques.rejected") }}</option>
            </select>
            <label for="date">{{ $t("cheques.cheque-date") }}</label>
            <input
              required
              type="date"
              class="form-control"
              v-model="data.cheque_date"
            />
          </div>
          <div class="modal-footer">
            <button
              type="submit"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.edit") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Edit Model-->
</template>
